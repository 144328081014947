import http from './base-api-service';

export const create = (data) => http.post('/projects', data)

export const list = () => http.get('/projects')

export const getSingle = (id) => {
    console.log(id)
    return http.get(`/projects/${id}`)
}

const projectService = {
    list,
    create,
    getSingle
}

export default projectService;