import { Grid } from '@mui/material'
import { TextCenter } from '../../../styles/commons'
import logo from '../../../assets/images/logoCircBlanco.svg'

function About () {
  return (
    <Grid
      container
      justifyContent='center'
      style={{ margin: '0 auto' }}
      sx={{ width: { xs: '200px', md: '400px' } }}
    >
      <Grid item>
        <img
          src={logo}
          alt=''
          style={{
            height: '200px',
            width: 'auto',
            margin: '25px'
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextCenter>
          Diseñar es crear con libertad; es comprender los deseos, las
          actividades y gustos de las personas; es lograr un equilibrio armónico
          entre los usuarios y los elementos que forman parte del espacio.
        </TextCenter>
      </Grid>
      <Grid item xs={12}>
        <TextCenter>
          Inspirados por todo lo que nos rodea, proponemos transformar espacios,
          creando ambientes auténticos, funcionales y estéticamente atractivos.
        </TextCenter>
      </Grid>
      <Grid item xs={12}>
        <TextCenter>
          Nos acercamos a nuestros clientes, captamos su personalidad, sus
          sueños y gustos y los aplicamos al diseño, transmitiendo sensaciones
          que los representen.
        </TextCenter>
      </Grid>
      <Grid item xs={12}>
        <TextCenter>
          <strong>Creamos espacios que son parte de una historia</strong>,
          espacios que reflejan la personalidad de las personas que lo habitan.
        </TextCenter>
      </Grid>
    </Grid>
  )
}

export default About
