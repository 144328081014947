import { Grid } from '@mui/material'
import { collection, getDocs } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../firebase.config'
import { Text, Title } from '../../../styles/commons'

export default function SingleProject () {
  const [project, setProject] = useState()
  const params = useParams()
  const { id } = params

  useEffect(() => {}, [project])

  const projectCollectionRef = collection(db, 'projects')

  useEffect(() => {
    const getProjects = async () => {
      const data = await getDocs(projectCollectionRef)
      const dataList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      dataList.map(d => (d.id === id ? setProject(d) : ''))
    }
    getProjects()
  })

  const screenWidth = window.innerWidth
  const imageSize = {
    width: screenWidth < 600 ? '100%' : 'fill',
    height: screenWidth >= 600 ? '100%' : 'fill',
    marginRight: screenWidth < 600 ? 0 : '15px',
    marginBottom: screenWidth < 600 ? '10px' : 0,
  }
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexWrap: 'nowrap'
      }}
      sx={{
        overflowY: { xs: 'none', sm: 'scroll', md: 'hidden' },
        overflowX: { xs: 'scroll', sm: 'none' },
        flexDirection: { xs: 'column', sm: 'row' },
        "&::-webkit-scrollbar": {
          width: 0,
          height: 6,
        },
        "&::-webkit-scrollbar-track": {
          background: "grey",
          margin: "15px",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "black",
          borderRadius: "5px",
          outline: "1px solid slategrey"
        }
      }}
    >
      <Grid item>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            padding: { xs: '25px' },
            width: { xs: '100%', sm: '400px' },
            justifyContent: { xs: 'flex-start' }
          }}
        >
          <Grid item>
            <Title sx={{ margin: '10px 0 0 0', padding: 0 }}>
              {project?.name.toUpperCase()}
            </Title>
          </Grid>

          <Grid item>
            <Grid
              container
              sx={{
                display: 'flex',
                width: { xs: '100%', sm: '400px' },
                justifyContent: { xs: 'center', sm: 'flex-start' },
                marginTop: '10px'
              }}
            >{/*
              <Subtitle>
                {project?.tags.toUpperCase()}
              </Subtitle>

              project?.tags.map((t, i) => (
                <Grid item key={i}>
                  <Title style={{ color: 'gray', marginRight: '5px' }}>
                    {t.toUpperCase()}
                  </Title>{' '}
                </Grid>
              ))*/}
            </Grid>
          </Grid>
          <Grid item>
            <Text>{project?.description}</Text>
          </Grid>
        </Grid>
      </Grid>
      {project?.imagesUrl.map((img, i) => (
        <Grid
          item
          key={i}
          sx={{
            width: { xs: '100%', sm: 'auto' },
            height: { xs: 'fill', sm: '600px' },
            marginBottom: "10px"
          }}
        >
          <img src={img} alt={project?.name + i} style={imageSize} />
        </Grid>
      ))}
    </Grid>
  )
}
