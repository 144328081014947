import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase.config'

export const doLogin = async userData => {
  try {
    const user = await signInWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    )
    localStorage.setItem('isAuth', true)
    return user
  } catch (error) {
  }
}

export function logoutUser () {
  localStorage.removeItem('isAuth')
  window.location.reload()
}
