import { Grid } from '@mui/material'
import logo from '../../../assets/images/letras-circle.svg'
import logo2 from '../../../assets/images/simbolo-solo.svg'
import { logoStyle } from './homeStyles'

export default function Home () {
  return (
    <Grid container justifyContent='center'>
      <Grid item sx={{
        backgroundImage: `url(${logo2})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "20%",
        backgroundPosition: "center"
      }}>
        <img
          src={logo}
          alt=''
          style={{
            width: '200px',
            height: 'auto',
            margin: '50px 0'
          }}
        />
      </Grid>
    </Grid>
  )
}
