export const cardProjectStyle = {
    padding: '15px',
    margin: '10px',
    width: '400px'
}

export  const tagStyle = {
    marginRight: '5px',
    color: 'gray',
    textTransformation: 'uppercase',
}

export const imageSnippetContainer = {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap'
}

export const imgSingle = {
    height: '600px',
    width: 'auto',
    marginRight: '25px'
}