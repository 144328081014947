import { FormControl, Grid, TextField, Button } from '@mui/material'
import { collection, addDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db, auth } from '../../../firebase.config'
import projectService from '../../../services/project-service'
import { SmallError, imageSnippet, formStyle } from '../../../styles/commons'
import { imageSnippetContainer } from '../../../styles/projectStyles'
import { getStorage } from 'firebase/storage'

export default function CreateProject () {
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    tags: ''
  })

  const imagesDB = getStorage()

  const [imagesUrl, setImagesUrl] = useState([])

  const [errors, setErrors] = useState({
    name: null,
    description: null
  })

  const projectCollectionRef = collection(db, 'projects')

  const createProjectFireBase = async () => {
    await addDoc(projectCollectionRef, {
      ...projectData,
      imagesUrl,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid }
    })
    navigate('/proyectos')
  }

  const navigate = useNavigate()

  let placeholderImage = ''

  useEffect(() => {}, [projectData])
  useEffect(() => {}, [errors])
  useEffect(() => {}, [imagesUrl])

  function doCreateProject () {
    let tags = projectData.tags.split(',')
    tags = tags
      .filter(t => t !== ' ')
      .map(t => t.replaceAll(' ', ''))
      .filter(t => t !== '')
    const data = {
      id: Math.floor(Math.random() * 100),
      name: projectData.name,
      description: projectData.description,
      tags,
      images: imagesUrl
    }
    console.log(data)
    projectService
      .create(data)
      .then(project => navigate('/proyectos'))
      .catch(e => console.log(e))
  }

  function validate () {
    let canCreate = false
    const textErrors = {
      name: 'Project name is required',
      description: 'Project description is required'
    }
    const nameRes =
      projectData.name === undefined || projectData.name === ''
        ? textErrors.name
        : null
    const descriptionRes =
      projectData.description === undefined || projectData.description === ''
        ? textErrors.description
        : null

    setErrors({
      name: nameRes,
      description: descriptionRes
    })

    if (nameRes === null && descriptionRes === null) canCreate = true
    if (canCreate === true) createProjectFireBase()
  }

  function deleteImage (index) {
    setImagesUrl(imagesUrl.filter(img => img !== index))
  }

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      style={{ padding: '0 25px' }}
    >
      <Grid item>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    label='Project Name'
                    variant='outlined'
                    value={projectData.name}
                    onChange={event =>
                      setProjectData({
                        ...projectData,
                        name: event.target.value
                      })
                    }
                    error={errors.name !== null}
                  />
                  {errors.name !== null ? (
                    <SmallError>{errors.name}</SmallError>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    label='Categorias'
                    placeholder='Escribe las categorias, separadas por comas y sin espacios'
                    variant='outlined'
                    value={projectData.tags}
                    onChange={event =>
                      setProjectData({
                        ...projectData,
                        tags: event.target.value
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    label='Project Description'
                    variant='outlined'
                    value={projectData.description}
                    onChange={event =>
                      setProjectData({
                        ...projectData,
                        description: event.target.value
                      })
                    }
                    error={errors.description !== null}
                    multiline
                    maxRows={4}
                    rows={4}
                  />
                  {errors.description !== null ? (
                    <SmallError>{errors.description}</SmallError>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Grid container direction='row'>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        style={formStyle}
                        type='text'
                        label='Image URL'
                        value={placeholderImage}
                        placeholder='paste the URL of your image'
                        onChange={event => {
                          console.log(event.target.value)
                          if (event.target.value.length > 6) {
                            setImagesUrl([...imagesUrl, event.target.value])
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction='row' style={imageSnippetContainer}>
                  {imagesUrl.map((img, i) => (
                    <img
                      src={img}
                      alt={img}
                      key={i}
                      style={imageSnippet}
                      onClick={() => deleteImage(img)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ margin: '0 auto' }}>
        <Button
          style={formStyle}
          onClick={() => validate()}
          variant='contained'
        >
          Crear Proyecto
        </Button>
      </Grid>
    </Grid>
  )
}
