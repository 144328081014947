import { Grid, FormControl, InputLabel, TextField, Button } from '@mui/material'
import { useState } from 'react'
import userServices from '../../../services/user-services'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { formStyle } from '../../../styles/commons'
import { auth } from '../../../firebase.config'

export default function CreateUser () {
  const [userData, setUserData] = useState({
    name: "",
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    name: null,
    email: null,
    password: null
  })

  const createUser = async () => {
    try {
      const user = await createUserWithEmailAndPassword(auth, userData.email, userData.password)
      console.log(user)
    } catch (error) {
      console.log(error.message)
    }
  }

  function validate () {
    let canCreate = false
    const textErrors = {
      email: 'Email is required',
      password: 'Password is required'
    }
    const emailRes =
      userData.email !== '' && userData.email !== undefined
        ? null
        : textErrors.email
    const passwordRes =
      userData.password !== '' && userData.password !== undefined
        ? null
        : textErrors.password

    setErrors({
      email: emailRes,
      password: passwordRes
    })

    if (emailRes === null && passwordRes === null)
      canCreate = true
    if (canCreate === true) createUser()
  }
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={3}
    >
      <Grid item>
        <FormControl>
          <TextField
            style={formStyle}
            variant='outlined'
            label='Name'
            type='text'
            value={userData.name}
            onChange={event =>
              setUserData({ ...userData, name: event.target.value })
            }
            error={errors.name !== null}
          />
          {errors.name !== null ? (
            <small style={{ color: 'red' }}>{errors.name}</small>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <TextField
            style={formStyle}
            variant='outlined'
            label='Email'
            type='text'
            value={userData.email}
            onChange={event =>
              setUserData({ ...userData, email: event.target.value })
            }
            error={errors.email !== null}
          />
          {errors.emails !== null ? (
            <small style={{ color: 'red' }}>{errors.emails}</small>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <TextField
            style={formStyle}
            variant='outlined'
            label='Password'
            type='password'
            value={userData.password}
            onChange={event =>
              setUserData({ ...userData, password: event.target.value })
            }
            error={errors.password !== null}
          />
          {errors.password !== null ? (
            <small style={{ color: 'red' }}>{errors.password}</small>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={() => validate()}>Submit</Button>
      </Grid>
    </Grid>
  )
}
