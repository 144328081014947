import { FormControl, Grid, InputLabel, TextField, Button } from '@mui/material'
import { useState } from 'react'
import { cardContainer, formStyle } from '../../../styles/commons'
import { useNavigate } from 'react-router-dom'
import { doLogin } from '../../../services/auth-service'

export default function Login ({ setAuth }) {
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  })

  const navigate = useNavigate()

  function userLogin () {
    doLogin(userData)
      .then(u => {
        if (u.operationType === 'signIn'){
          navigate('/')
          window.location.reload()
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <Grid
      container
      justifyContent='center'
      style={cardContainer}
      direction='column'
      alignItems='center'
      spacing={3}
    >
      <Grid item>
        <FormControl>
          <InputLabel>Email</InputLabel>
          <TextField
            style={formStyle}
            autoComplete='off'
            type='email'
            placeholder='Email'
            value={userData.email}
            onChange={event =>
              setUserData({ ...userData, email: event.target.value })
            }
            variant='outlined'
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel>Password</InputLabel>
          <TextField
            style={formStyle}
            autoComplete='off'
            variant='outlined'
            type='password'
            placeholder='Password'
            value={userData.password}
            onChange={event =>
              setUserData({ ...userData, password: event.target.value })
            }
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={() => userLogin()}>Submit</Button>
      </Grid>
    </Grid>
  )
}
