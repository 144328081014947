import axios from 'axios';

export const currentUserStorageKey = 'currentUser';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api'
})

http.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {

  const status = error?.response?.status;

  switch (status) {
      case 401:
          localStorage.removeItem(currentUserStorageKey)
          break
      default:
        break
  }
  
  if (error?.response?.status === 401) {
      localStorage.removeItem(currentUserStorageKey);
      window.location.replace('/login')
  }

  return Promise.reject(error);
});

export default http;