import { Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Title, Text } from '../../../styles/commons'

function ProjectCard (info) {
  const { data } = info
  const screenWidth = window.innerWidth
  const imageMax = '500px'
  const imageSize = {
    width: screenWidth < 600 ? screenWidth : imageMax,
    height: screenWidth < 600 ? screenWidth : imageMax
  }
  return (
    <Grid
      container
      sx={{
        width: { sm: screenWidth },
        margin: { xs: 0, sm: '10px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'center', sm: 'flex-start' }
      }}
    >
      <Grid item sx={{ margin: { xs: '0 auto', sm: '0' } }}>
        <Link to={`/proyectos/${data.id}`}>
          <Grid
            style={imageSize}
            alt={data.name}
            sx={{
              backgroundImage: `url(${data.imagesUrl[0]})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
        </Link>
      </Grid>
      <Grid item sx={{ margin: { xs: '10px auto 0', sm: '10px 0' } }}>
        <Title>{data.name.toUpperCase()}</Title>
      </Grid>
      <Grid item>
        <Grid
          item
          sx={{
            color: 'gray',
            display: 'flex',
            flexDirection: { xs: 'column' },
            justifyContent: { xs: 'center' }
          }}
        >
          {/*
          <Grid item sx={{ margin: { xs: '0 auto', sm: '0' } }}>
            <Subtitle>{data.tags.toUpperCase()}</Subtitle>
          </Grid>
          data.tags.map((t, i) => (
            <small key={i} style={tagStyle}>
              {t.toUpperCase()}
            </small>
          ))*/}
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: { xs: screenWidth, sm: imageMax },
          margin: { xs: '5px auto', sm: '0' },
          padding: { xs: '0 10px', sm: 0 }
        }}
      >
        {/*<Text>{data.description.slice(0, 100) + '...'}</Text>*/}
      </Grid>
    </Grid>
  )
}

export default ProjectCard
