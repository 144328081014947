import { CircularProgress, Grid } from '@mui/material'
import { collection, getDocs } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { db } from '../../../firebase.config'
import { linkStyle, NewLinkItem } from '../../../styles/commons'

export default function NewsSection () {
  const [news, setNews] = useState()
  const newCollectionRef = collection(db, 'news')

  useEffect(() => {
    const getNewsFireBase = async () => {
      const data = await getDocs(newCollectionRef)
      const newsList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      setNews(newsList)
    }
    getNewsFireBase()
  }, [])

  useEffect(() => {}, [news])

  return (
    <Grid
      container
      justifyContent='center'
      direction='column'
      alignItems='flex-start'
      spacing={1}
    >
      {news === undefined ? (
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <CircularProgress style={{color: 'white'}} />
        </Grid>
      ) : (
        news.map((n, i) => (
          <Grid container direction='row' key={i} justifyContent='center'>
            <NewLinkItem>
              <a
                href={n.link}
                target='_blank'
                rel='noreferrer'
                style={linkStyle}
              >
               {n.title}
              </a>
            </NewLinkItem>
          </Grid>
        ))
      )}
    </Grid>
  )
}
