import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Layout from '../components/views/layout/layout'

const RouterWrapper = () => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"))
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
  }, [isAuth])

  function setAuth () {
    setIsAuth(true)
  }

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout isAuth={isAuth} />} />
        <Route exact path='/proyectos' element={<Layout isAuth={isAuth} />} />
        <Route exact path='/proyectos/:id' element={<Layout isAuth={isAuth} />} />
          <Route
          exact
          path='/add-project'
          element={<Layout isAuth={isAuth}/>}
        />
        <Route exact path='/about' element={<Layout isAuth={isAuth} />} />
        <Route exact path='/contact' element={<Layout isAuth={isAuth} />} />
        <Route exact path='/news' element={<Layout isAuth={isAuth} />} />
        <Route exact path='/add-new' element={<Layout isAuth={isAuth} />} />

        <Route
          exact
          path='/login'
          element={<Layout isAuth={isAuth} setAuth={setAuth} />}
        />

        {/*
        <Route
          exact
          path='/create'
          element={<Layout isAuth={isAuth} />}
          isAuth={isAuth}
          setIsAuth={setIsAuth}
        />
        */}
        {redirect === true ? <Navigate replace to='/' /> : ''}
      </Routes>
    </Router>
  )
}

export default RouterWrapper
