// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEhaw6csBi5VFjb2ypPQar8EdhAQ1ouzs",
  authDomain: "angelapastorinteriorismo-b1214.firebaseapp.com",
  projectId: "angelapastorinteriorismo-b1214",
  storageBucket: "angelapastorinteriorismo-b1214.appspot.com",
  messagingSenderId: "267417196755",
  appId: "1:267417196755:web:dde20275a4ba4e32731c26",
  measurementId: "G-25VWBKTNF5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app); 
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
export const db = getFirestore(app)