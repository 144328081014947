import { Button, FormControl, Grid, TextField } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import {
  linkNoStyle,
  SmallError,
  Title,
  formStyle,
  Text
} from '../../../styles/commons'
import { useState } from 'react'

function Contact () {
  const [mail, setMail] = useState({
    nombre: '',
    apellido: '',
    email: '',
    asunto: '',
    mensaje: ''
  })

  const [errors, setErrors] = useState({
    nombre: null,
    email: null,
    asunto: null,
    mensaje: null
  })

  function sendMail () {
    return
  }

  function validarFormularioEnvio () {
    let canSend = false
    const textErrors = {
      nombre: 'El nombre es necesario',
      email: 'El email es necesario',
      asunto: 'El asunto es necesario',
      mensaje: 'El mensaje es neceario'
    }
    const nombreRes =
      mail.nombre === undefined || mail.nombre === null || mail.nombre === ''
        ? textErrors.nombre
        : null
    const emailRes =
      mail.email === undefined || mail.email === null || mail.email === ''
        ? textErrors.email
        : null
    const asuntoRes =
      mail.asunto === undefined || mail.asunto === null || mail.asunto === ''
        ? textErrors.asunto
        : null
    const mensajeRes =
      mail.mensaje === undefined || mail.mensaje === null || mail.mensaje === ''
        ? textErrors.mensaje
        : null

    setErrors({
      nombre: nombreRes,
      email: emailRes,
      asunto: asuntoRes,
      mensaje: mensajeRes
    })

    if (
      nombreRes === null &&
      emailRes === null &&
      asuntoRes === null &&
      mensajeRes === null
    )
      canSend = true
    if (canSend === true) sendMail()
  }
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid item xs={12} sm={3}>
        <Grid container direction='column' style={{padding: '15px'}}>
          <Grid item>
            <Title>Ángela Pastor</Title>
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center'>
              <EmailIcon style={{ marginRight: '10px' }} />
              <Text>hola@angelapastorinteriorismo.com</Text>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center'>
              <LocalPhoneIcon style={{ marginRight: '10px' }} />
              <Text>+34 690 18 19 60</Text>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center'>
              <a
                href='https://www.instagram.com/angelapastor_interiorismo/'
                style={linkNoStyle}
              >
                <InstagramIcon style={{ marginRight: '10px', color: 'white' }} />
              </a>
              <a
                href='https://www.instagram.com/angelapastor_interiorismo/'
                style={linkNoStyle}
              >
                <Text style={{color: 'white'}}>@angelapastor_interiorismo</Text>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8} style={{padding: '15px'}}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Title>Formulario de contacto</Title>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={1}>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    color='primary'
                    name='Nombre'
                    label='Nombre'
                    variant='outlined'
                    onChange={e => setMail({ ...mail, nombre: e.target.value })}
                    error={errors.nombre !== null}
                  />
                  {errors.nombre !== null ? (
                    <SmallError>{errors.nombre}</SmallError>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    name='Apellido'
                    label='Apellido'
                    variant='outlined'
                    onChange={e =>
                      setMail({ ...mail, apellido: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    name='Asunto'
                    label='Asunto'
                    variant='outlined'
                    onChange={e => setMail({ ...mail, asunto: e.target.value })}
                    error={errors.asunto !== null}
                  />
                  {errors.asunto !== null ? (
                    <SmallError>{errors.asunto}</SmallError>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row'>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    style={formStyle}
                    name='Email'
                    label='Email'
                    variant='outlined'
                    onChange={e => setMail({ ...mail, email: e.target.value })}
                    error={errors.email !== null}
                  />
                  {errors.email !== null ? (
                    <SmallError>{errors.email}</SmallError>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                name='Mensaje'
                style={formStyle}
                label='Mensaje'
                variant='outlined'
                multiline
                maxRows={4}
                rows={4}
                onChange={e => setMail({ ...mail, mensaje: e.target.value })}
                error={errors.mensaje !== null}
              />
              {errors.mensaje !== null ? (
                <SmallError>{errors.mensaje}</SmallError>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              style={formStyle}
              variant='outlined'
              onClick={() => validarFormularioEnvio()}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact
