import { Container, Grid } from '@mui/material'
import { footerText, footerStyle } from './footerStyle'

export default function Footer () {
  return (
    <Container style={footerStyle}>
      <Grid container direction='row' justifyContent='space-around'>
      </Grid>
    </Container>
  )
}
