import styled from '@emotion/styled'
import bg from '../assets/images/bg-tile.jpg'

export const Title = styled.p`
  font-size: 15px;
  font-family: 'Monterrat', sans-serif;
  margin: 0;
  font-weight: 700;
`

export const Subtitle = styled.p`
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #e8e4da;
  margin: 0,
  padding: 0;
`

export const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 100;
`

export const TextCenter = styled.p`
  text-align: center;
  font-family: 'Montserrat', light;
  font-size: 13px;
  line-height: 20px;
  font-weight: 100;
`

export const NewLinkItem = styled.div`
  margin-bottom: 15px;
  text-align: center,
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #e8e4da;
`

export const LinkNavbar = styled.div`
  padding: 0 15px 25px 15px;
  text-align: center,
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #e8e4da;
`

export const smallContainer = {
  width: window.innerWidth / 3,
  margin: '0 auto'
}

export const cardContainer = {
  maxWidth: window.innerWidth - window.innerWidth / 5,
  margin: '0 auto',
  border: '1px solid grey',
  borderRadius: '5px',
  padding: '15px'
}

export const SmallError = styled.small`
  color: red;
`

export const btnNoStyle = {
  border: '1px solid rgba(0,0,0,0)'
}

export const containerScrollY = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  overflowY: 'scroll'
}

export const containerScrollX = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflowX: 'scroll'
}

export const imageSnippet = {
  width: '75px',
  height: '75px',
  marginTop: '5px',
  marginRight: '5px'
}

export const linkNoStyle = {
  textDecoration: 'none',
  color: 'black'
}

export const darkTheme = {
  backgroundImage: `url(${bg})`
}

export const formStyle = {
  color: 'white',
  backgroundColor: 'gray',
  border: '1px solid white',
  borderRadius: '5px'
}

export const linkStyle = {
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '500',
  textDecoration: "underline",
  color: "white",
  fontSize: '13px'
}
