import { Grid } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import Footer from '../../sections/footer/footer'
import NavBar from '../../sections/navbar/navbar'
import About from '../about/about'
import Contact from '../contact/contact'
import Home from '../home/home'
import CreateUser from '../login/createUser'
import Login from '../login/login'
import CreateNew from '../news/createNew'
import NewsSection from '../news/news'
import CreateProject from '../projects/createProject'
import Projects from '../projects/projects'
import SingleProject from '../projects/singleProject'
import { darkTheme } from '../../../styles/commons'

export default function Layout ({ isAuth }) {
  let theComponent = <Home />
  const location = useLocation()
  const params = useParams()
  switch (location.pathname) {
    case '/proyectos':
      theComponent = <Projects />
      break
    case `/proyectos/${params.id}`:
      theComponent = <SingleProject />
      break
    case '/contact':
      theComponent = <Contact />
      break
    case '/news':
      theComponent = <NewsSection />
      break
    case '/about':
      theComponent = <About />
      break
    case '/login':
      theComponent = <Login />
      break
    case '/create':
      theComponent = <CreateUser />
      break
    case '/add-project':
      theComponent = <CreateProject />
      break
      case '/add-new':
        theComponent = <CreateNew />
        break
    default:
      break
  }
  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      style={darkTheme}
      sx={{
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        color: 'white',
        minHeight: '100vh'
      }}
    >
      <NavBar isAuth={isAuth} />
      {theComponent}
      <Footer />
    </Grid>
  )
}
