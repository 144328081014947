import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Button
} from '@mui/material'
import { addDoc, collection } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../firebase.config'
import newsServices from '../../../services/news-service'
import { formStyle } from '../../../styles/commons'

export default function CreateNew () {
  const navigate = useNavigate()
  const [newData, setNewData] = useState({
    title: '',
    link: '',
    tag: ''
  })

  useEffect(() => {
    console.log(newData)
  }, [newData])

  const newCollectionRef = collection(db, 'news')

  const createNewFireBase = async () => {
    await addDoc(newCollectionRef, {
      ...newData,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid }
    })
    navigate('/news')
  } 

  return (
    <Grid
      container
      justifyContent='center'
      spacing={2}
      style={{padding: '50px'}}
    >
      <Grid item xs={12}>
        <FormControl style={{ width: '100%' }}>
          <TextField
            style={formStyle}
            label='Title'
            type='text'
            value={newData.title}
            onChange={event =>
              setNewData({ ...newData, title: event.target.value })
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl style={{ width: '100%' }}>
          <Select
            style={formStyle}
            label='Categoria'
            onChange={event => {
              setNewData({ ...newData, tag: event.target.value })
            }}
          >
            <MenuItem value='Periodico'>Periodico</MenuItem>
            <MenuItem value='Radio'>Radio</MenuItem>
            <MenuItem value='TV'>TV</MenuItem>
            <MenuItem value='Web'>Web</MenuItem>
            <MenuItem value='Podcast'>Podcast</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl style={{ width: '100%' }}>
          <TextField
            style={formStyle}
            label='Link'
            type='text'
            value={newData.link}
            onChange={event =>
              setNewData({ ...newData, link: event.target.value })
            }
          />
        </FormControl>
      </Grid>

      <Grid item>
        <Button variant='contained' onClick={() => createNewFireBase()}>
          Create New
        </Button>
      </Grid>
    </Grid>
  )
}
