import React from "react";
import AuthStore from './contexts/AuthStore'
import RouterWrapper from './system/RouterWrapper'

export default function App() {
  return (
    <AuthStore>
      <RouterWrapper />
    </AuthStore>
  );
}
