import React, { useEffect } from 'react'
import { Box, Button, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { navBarStyle, linkNoStyle, LinkNavbar } from './navbarStyle'
import logo from '../../../assets/images/logoAPI.svg'
import { Link, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { logoutUser } from '../../../services/auth-service'

export default function NavBar ({ isAuth }) {
  const pages = [
    { name: 'Proyectos', link: '/proyectos' },
    { name: 'El estudio', link: '/about' },
    { name: 'Prensa y radio', link: '/news' },
    { name: 'Contacto', link: '/contact' }
  ]

  const url = window.location.pathname
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  function logout () {
    logoutUser()
  }

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  useEffect(() => {}, [isAuth])

  return (
    <Grid container direction='row' style={navBarStyle} alignItems='center'>
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          justifyContent: { xs: 'center', sm: 'flex-start' },
          alignItems: { xs: 'center', sm: 'center' }
        }}
      >
        <a href='/' style={{ display: 'flex' }}>
          <img src={logo} alt='' style={{ margin: '0 auto', height: '40px' }} />
        </a>
      </Grid>
      {/* MENU EN XS Y SM */}
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'flex', md: 'none' },
          width: window.screen.width
        }}
        justifyContent='center'
        alignContent='center'
        alignItems='center'
      >
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
          color='inherit'
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            padding: 0,
            display: { xs: 'flex', md: 'none' }
          }}
          MenuListProps={{ disablePadding: true }}
        >
          {pages.map((page, i) => (
            <MenuItem
              key={i}
              onClick={handleCloseNavMenu}
              sx={{
                background: 'black',
                width: window.screen.width,
                padding: '45px 0'
              }}
            >
              <Link
                to={page.link}
                style={{ textDecoration: 'none', marginLeft: '50px' }}
              >
                <linkNoStyle style={linkNoStyle}>{page.name}</linkNoStyle>
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* MENU EN A PARTIR DE MD */}

      <Grid
        item
        xs={8}
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
      >
        <Grid container direction='row' justifyContent='center' spacing={3}>
          {pages.map((p, i) => (
            <Grid item key={i}>
              <a href={p.link} style={linkNoStyle}>
                <LinkNavbar>{p.name.toUpperCase()}</LinkNavbar>
              </a>
            </Grid>
          ))}
        </Grid>

      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
      >
        {isAuth !== null ? (
          <Grid container direction='row'>
            <Grid item>
              <Button onClick={() => logout()}>Logout</Button>
            </Grid>
            {url === '/proyectos' ? (
              <Grid item>
                <Button onClick={() => navigate('/add-project')}>
                  Add Project
                </Button>
              </Grid>
            ) : (
              ''
            )}
            {url === '/news' ? (
              <Grid item>
                <Button onClick={() => navigate('/add-new')}>Add New</Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  )
}
