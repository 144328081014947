import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import ProjectCard from './projectCard'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../firebase.config'

export default function Projects () {
  const [projectsList, setProjectList] = useState([])

  const projectCollectionRef = collection(db, 'projects')

  useEffect(() => {
    const getProjects = async () => {
      const data = await getDocs(projectCollectionRef)
      const dataList = data.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      const dataListRev = dataList.reverse()
      setProjectList(dataListRev)
    }
    getProjects()
  }, [])

  useEffect(() => {}, [projectsList])

  return (
    <Grid
      container
      sx={{
        display: { sm: 'flex' },
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        overflowY: { xs: 'none', sm: 'scroll' },
        "&::-webkit-scrollbar": {
          width: 0,
          height: 6,
        },
        "&::-webkit-scrollbar-track": {
          background: "grey",
          margin: "15px",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "black",
          borderRadius: "5px",
          outline: "1px solid slategrey"
        }
      }}
    >
      {projectsList.length === 0 ? (
        <Grid
          sx={{
            display: { sm: 'flex' },
            flexDirection: { xs: 'column' },
            justifyContent: { xs: 'center' },
            alignItems: { xs: 'center' }
          }}
          container
        >
          <CircularProgress style={{ color: 'white' }} />
        </Grid>
      ) : (
        projectsList?.map(p => <ProjectCard data={p} key={p.id} />)
      )}
    </Grid>
  )
}
