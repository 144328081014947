import styled from '@emotion/styled'

export const navBarStyle = {
  padding: '25px',
  borderBottom: '1px solid grey',
  color: 'white'
}

export const linkNoStyle = {
  textDecoration: 'none',
  color: '#9e9e9e',
  fontFamily: 'Montserrat, light',
  fontWeight: 500,
}

export const LinkNavbar = styled.p`
  font-family: 'Montserrat', light;
  font-size: 13px;
  font-weight: 600;
  color: #e4e5e7;
`

export const PageLink = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 500
}

export const logoStyle = {
    height: '40px',
}